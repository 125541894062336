// services/S3Service.js

class S3Service {
  async fetchJobsFromS3() {
    try {
      const response = await fetch('https://granlov-jobscanner-config.s3.eu-north-1.amazonaws.com/jobs.json');
      if (!response.ok) {
        throw new Error('Failed to fetch jobs from S3');
      }
      const data = await response.json();
      const files = data.filter(str => str.endsWith('.json'));
      const jobs = await this.getJobsFromFiles(files);
      const timestamp = response.headers.get('last-modified');
      console.log('Loaded jobs.json', {
          'number of job files': files.length,
          'last-modified': timestamp
        });
      return jobs;
    } catch (error) {
      console.error('Error fetching jobs from S3:', error);
      throw error;
    }
  }

  extractJsonFiles(data) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(data, 'text/xml');
    const contents = xml.getElementsByTagName('Contents');
    const files = [];

    for (let i = 0; i < contents.length; i++) {
      const key = contents[i].getElementsByTagName('Key')[0].textContent;
      if (key.endsWith('.json')) {
        files.push(key);
      }
    }

    return files;
  }

  async getJobsFromFiles(files) {
    const jobs = [];

    for (const file of files) {
      try {
        const response = await fetch(`https://granlov-jobscanner-config.s3.eu-north-1.amazonaws.com/${encodeURIComponent(file)}`);
        if (!response.ok) {
          console.error(`Failed to fetch ${file} from S3`);
          continue;
        }
        const job = await response.json();
        jobs.push(job);
      } catch (error) {
        console.error(`Error fetching ${file} from S3:`, error);
      }
    }

    return jobs;
  }
}

export default S3Service;
