// App.js

import React, { useState, useEffect } from 'react';
import JobList from './components/JobList';
import JobDetail from './components/JobDetail';
import S3Service from './services/S3Service';
import './App.css';

function App() {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    const s3Service = new S3Service();
    s3Service.fetchJobsFromS3()
      .then(data => setJobs(data))
      .catch(error => console.error('Error fetching jobs:', error));
  }, []);


  return (
    <div className="app">
      <h1>Jobb ({jobs.length} aktiva annonser)</h1>
      <div className="job-list-container">
        <JobList jobs={jobs} setSelectedJob={setSelectedJob} />
        {selectedJob && <JobDetail job={selectedJob} />}
      </div>
    </div>
  );
}

export default App;
