// components/JobList.js

import React from 'react';
import './JobList.css'; // Import the CSS file for JobList styling

const JobList = ({ jobs, setSelectedJob, selectedJob }) => {
  return (
    <ul className="job-list">
      {jobs.map(job => {
        return (
          <li key={job.uuid} className="job-item" onClick={() => setSelectedJob(job)}>
            <h2 className="job-employer">{job.employer}</h2>
            <p className="job-title">{job.title}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default JobList;
