// components/JobDetail.js

import React from 'react';

const JobDetail = ({ job }) => {
  return (
    <div className="job-detail">
      <h2>
        <span className="job-employer">{job.employer}</span><br/>
        &mdash; <span className="job-title">{job.title}</span>
      </h2>
      
      <p className="ai_summary">{job.ai_summary}</p>

      <div className="right">
        <p>
          <a className="button" href={job.url} target="_blank" rel="noopener noreferrer">
            Läs mer och ansök
          </a>
        </p>
        <p>Deadline {job.deadline}</p>
      </div>
    </div>
  );
};

export default JobDetail;
